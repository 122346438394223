import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  articles: {},
  modalActive: false,
  selectedEntity: "Node Information",
  dataTables: [],
  dataSources: [],
  charts: [],
  mapLoading: false,
  dataLoading: false,
  searchMarkers: []
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_NODE_INFORMATION:
      return {
        ...state,
        selectedEntity: payload.data
      };
    case actions.LOAD_DATA_TABLE_INFORMATION:
      return {
        ...state,
        dataTables: payload.data
      };
    case actions.LOAD_CHART_INFORMATION:
      return {
        ...state,
        charts: payload.data
      };
    case actions.LOAD_DATA_SOURCE_INFORMATION:
      return {
        ...state,
        dataSources: payload.data
      };
    case actions.LOAD_SET_LOADING_INFORMATION:
      return {
        ...state,
        mapLoading: payload.data
      };
    case actions.LOAD_SET_DATA_LOADING_INFORMATION:
      return {
        ...state,
        dataLoading: payload.data
      };
    case actions.LOAD_SET_SEARCH_MARKERS_INFORMATION:
      return {
        ...state,
        searchMarkers: payload.data
      };
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        article: payload.data == null ? initState.article : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        article: payload.data,
      };
    default:
      return state;
  }
}
