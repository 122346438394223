import React, {useEffect, useState} from 'react';
import './App.css';
//import { withAuthenticator } from '@aws-amplify/ui-react'

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store, persistor } from './redux/store';
import Routes from './router';
import AppProvider from './AppProvider';
import { useClearCache } from "react-clear-cache";

function App() {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();
    const [urlLocation, setURLLocation] = useState("");

    useEffect(() => {
        if(!isLatestVersion) {
            emptyCacheStorage();
        }

        let url = decodeURIComponent(window.location.href);
        setURLLocation(url);
    });

    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppProvider>
                        <>
                            <GlobalStyles />
                            <Routes />
                        </>
                    </AppProvider>
                </PersistGate>
            </Provider>
            {(urlLocation !== "" && !urlLocation.includes("/viewer")) || (urlLocation.includes("/viewer") && !urlLocation.includes("elements")) ?
                <div>
                </div>
                :
                <div></div>
            }
        </div>
    );
}

/*<LiveChat license={12212568} />*/

//export default  withAuthenticator(App)
export default App

/*
const App = () => (
    <Provider store={store}>
        <AppProvider>
            <>
                <GlobalStyles />
                <Routes />
            </>
        </AppProvider>
    </Provider>
);
Boot()
    .then(() => App())
    .catch(error => console.error(error));

export default withAuthenticator(App)
*/