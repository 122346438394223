const DOCUMENT = 'USER_';

const actions = {
    LOAD_USER_INFORMATION: DOCUMENT + 'LOAD_USER_INFORMATION',

    loadUserInformation: (data = null) => ({
        type: actions.LOAD_USER_INFORMATION,
        payload: { data }
    })
};
export default actions;
