import React from 'react';
import LoaderComponent from './loaderTelemusAI.style.js';

export default Loader => (
    <LoaderComponent>
        <div style={
            {"width": "120px",
             "height": "120px",
             "background": "url(https://spatial.telemus.ai/images/telemus_ai_loading.png)"}
        }>
        </div>
    </LoaderComponent>
);
