import styled from 'styled-components';

const LoaderComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10000000000;
  top: 0;
  left: 0;
  background: #0d1114;
  opacity: 0.8;
`;

export default LoaderComponent;
