import actions from './actions';

const initState = {
    "cognito_user_id": "",
    "username": "",
    "email": "",
    "first_name": "",
    "last_name": "",
    "mobile_no": "",
    "stripe_customer_id": "",
    "subscription_level": "",
    "avatar_url": "",
    "stripe_subscription_id": "",
    "session_token": ""
};

export default function reducer(
    state = initState,
    { type, payload, newRecord }
) {
    switch (type) {
        case actions.LOAD_USER_INFORMATION:
            return {
                ...state,
                cognito_user_id: payload.data.cognito_user_id === "" ? state.cognito_user_id : payload.data.cognito_user_id,
                username: payload.data.username,
                email: payload.data.email,
                first_name: payload.data.first_name === "" ? state.first_name : payload.data.first_name,
                last_name: payload.data.last_name === "" ? state.last_name : payload.data.last_name,
                mobile_no: payload.data.mobile_no,
                stripe_customer_id: payload.data.stripe_customer_id === "" ? state.stripe_customer_id : payload.data.stripe_customer_id,
                subscription_level: payload.data.subscription_level,
                avatar_url: payload.data.avatar_url === "" ? state.avatar_url : payload.data.avatar_url,
                stripe_subscription_id: payload.data.stripe_subscription_id,
                session_token: payload.data.session_token
            };
        default:
            return state;
    }
}
