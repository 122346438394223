const DOCUMENT = 'ARTICLE_';

const actions = {
  LOAD_NODE_INFORMATION: DOCUMENT + 'LOAD_NODE_INFORMATION',
  LOAD_DATA_TABLE_INFORMATION: DOCUMENT + 'LOAD_DATA_TABLE_INFORMATION',
  LOAD_CHART_INFORMATION: DOCUMENT + 'LOAD_CHART_INFORMATION',
  LOAD_DATA_SOURCE_INFORMATION: DOCUMENT + 'LOAD_DATA_SOURCE_INFORMATION',
  LOAD_SET_LOADING_INFORMATION: DOCUMENT + 'LOAD_SET_LOADING_INFORMATION',
  LOAD_SET_DATA_LOADING_INFORMATION: DOCUMENT + 'LOAD_SET_DATA_LOADING_INFORMATION',
  LOAD_SET_SEARCH_MARKERS_INFORMATION:  DOCUMENT + 'LOAD_SET_SEARCH_MARKERS_INFORMATION',

  TOGGLE_FIRESTORE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_FIRESTORE_HANDLE_MODAL',
  FIRESTORE_UPDATE: DOCUMENT + 'FIRESTORE_UPDATE',

  loadNodeInformation: (data = null) => ({
    type: actions.LOAD_NODE_INFORMATION,
    payload: { data }
  }),

  loadSetLoadingInformation: (data = null) => ({
    type: actions.LOAD_SET_LOADING_INFORMATION,
    payload: { data }
  }),

  loadSetDataLoadingInformation: (data = null) => ({
    type: actions.LOAD_SET_DATA_LOADING_INFORMATION,
    payload: { data }
  }),

  loadSetSearchMarkersInformation: (data = null) => ({
    type: actions.LOAD_SET_SEARCH_MARKERS_INFORMATION,
    payload: { data }
  }),

  loadDataTableInformation: (data = null) => ({
    type: actions.LOAD_DATA_TABLE_INFORMATION,
    payload: { data }
  }),

  loadChartInformation: (data = null) => ({
    type: actions.LOAD_CHART_INFORMATION,
    payload: { data }
  }),

  loadDataSources: (data = null) => ({
    type: actions.LOAD_DATA_SOURCE_INFORMATION,
    payload: { data }
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_FIRESTORE_HANDLE_MODAL,
    payload: { data },
  }),

  update: data => ({
    type: actions.FIRESTORE_UPDATE,
    payload: { data },
  })
};
export default actions;
