export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  VIEWER: '/viewer',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
  GOOGLE_MAP: '/googlemap',
  FORM_WITH_STEPPERS: '/stepperForms',
  FORM_WITH_VALIDATION: '/FormsWithValidation',
  TAB: '/tab',
  AUTOCOMPLETE: '/autocomplete',
  CHECKBOX: '/checkbox',
  RADIOBOX: '/radiobox',
  SELECT: '/selectbox',
  TRANSFER: '/transfer',
  GRID_LAYOUT: '/gridLayout',
  ALERT: '/alert',
  MODAL: '/modal',
  NOTIFICATION: '/notification',
  POPCONFIRM: '/Popconfirm',
  BACK_TO_TOP: '/backToTop',
  MENU: '/menu',
};
