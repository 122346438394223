/* global window.isDashboardVisible */

import { getDefaultPath } from '@iso/lib/helpers/url_sync';
import actions, { getView } from './actions';
import { isServer } from '@iso/lib/helpers/isServer';
const preKeys = getDefaultPath();
const initState = {
  collapsed: !isServer && window.innerWidth > 1220 ? false : true,
  view: !isServer && getView(window.innerWidth),
  height: !isServer && window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  openLayers: [],
  current: preKeys,
  isDashboardVisible: true,
  showSubscriptionPopupOverlay: false,
  network: null,
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_OPEN_LAYERS:
      return {
        ...state,
        openLayers: action.openLayers,
      };
    case actions.CHANGE_NETWORK:
      return {
        ...state,
        network: action.network,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.CHANGE_IS_DASHBOARD_VISIBLE:
      return {
        ...state,
        isDashboardVisible: action.isDashboardVisible,
      };
    case actions.CHANGE_SHOW_SUBSCRIPTION_POPUP_OVERLAY:
      return {
        ...state,
        showSubscriptionPopupOverlay: action.showSubscriptionPopupOverlay,
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        openLayers: [],
        current: [],
        isDashboardVisible: true
      };
    default:
      return state;
  }
  return state;
}
