import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import Articles from '@iso/redux/articles/reducers';
import UserInformation from '@iso/redux/user/reducers';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Articles,
  UserInformation,
  modal,
  profile
});
