import storage from 'redux-persist/lib/storage'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

import { persistStore, persistReducer } from 'redux-persist';

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'App',
    'ThemeSwitcher',
    'LanguageSwitcher',
    'Articles',
    'UserInformation',
    'modal',
    'profile'
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    '',
  ],
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(persistedReducer, bindMiddleware(middlewares));
sagaMiddleware.run(rootSaga);

let persistor = persistStore(store);
// Exports
export {
  store,
  persistor,
};
