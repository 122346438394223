import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      let rootElement = document.getElementById("root");
      document.body.style.backgroundColor = "#0d1114";
      document.body.style.fontFamily = "Segoe UI,SegoeUI,\"Helvetica Neue\",Helvetica,Arial,sans-serif";

      let browserSupportHtml = "<div style='width:100%; height:100%; padding-top: 150px'>";
      browserSupportHtml += "<div style='font-size: 16px;  color: white; text-align: center;'><a href='https://telemus.ai'><img src='https://spatial.telemus.ai/images/telemus_ai_logo.svg' height='91px' alt='Telemus AI Spatial Logo'/></a></div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "<div style='font-size: 24px;  color: #f50d1b; text-align: center; font-weight: bold;'>There seems to be an issue loading Telemus AI Spatial on your device.</div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "<div style='font-size: 18px;  color: white; text-align: center; font-weight: bold;'>Please refresh your page (F5), clearing your browser cache may also help loading the platform.</div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "<div style='font-size: 18px;  color: white; text-align: center; font-weight: bold;'>If you continue to experience issues, email <a href='mailto:support@telemus.ai' style='color: white'>support@telemus.ai</a> and we will be happy to help.</div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "<div style='font-size: 24px;  color: white; text-align: center;'>Telemus AI Spatial supports Chrome, Edge, Safari and Firefox.</div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "<div style='font-size: 18px; color: white; text-align: center; padding: 7px;'>Please open a supported browser to use Telemus AI Spatial.</div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "<div style='font-size: 16px;  color: white; text-align: center;'>"
      browserSupportHtml += "<span><a href='https://www.google.com/intl/en_au/chrome/'><img src='https://spatial.telemus.ai/images/chrome.svg' width='100px' alt='Chrome Logo' style='padding: 15px;'/></a></span>";
      browserSupportHtml += "<span><a href='https://www.microsoft.com/en-us/edge'><img src='https://spatial.telemus.ai/images/edge.svg' width='100px' alt='Edge Logo' style='padding: 15px;'/></a></span>";
      browserSupportHtml += "<span><a href='https://support.apple.com/en_AU/downloads/safari'><img src='https://spatial.telemus.ai/images/safari.svg' width='100px' alt='Safari Logo' style='padding: 15px;'/></a></span>";
      browserSupportHtml += "<span><a href='https://www.mozilla.org/en-US/firefox/new/'><img src='https://spatial.telemus.ai/images/firefox.svg' width='100px' alt='Firefox Logo' style='padding: 15px;'/></a></span>";
      browserSupportHtml += "</div>";
      browserSupportHtml += "<br/>";
      browserSupportHtml += "</div>";

      rootElement.innerHTML = browserSupportHtml;

      return <p>Loading failed! Please reload.</p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
