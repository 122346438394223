export function getView(width) {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}
const actions = {
  COLLPSE_CHANGE: 'COLLPSE_CHANGE',
  COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
  CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
  CHANGE_OPEN_LAYERS: 'CHANGE_OPEN_LAYERS',
  TOGGLE_ALL: 'TOGGLE_ALL',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  CHANGE_IS_DASHBOARD_VISIBLE: 'CHANGE_IS_DASHBOARD_VISIBLE',
  CHANGE_SHOW_SUBSCRIPTION_POPUP_OVERLAY: 'CHANGE_SHOW_SUBSCRIPTION_POPUP_OVERLAY',
  CLEAR_MENU: 'CLEAR_MENU',
  CHANGE_NETWORK: 'CHANGE_NETWORK',

  toggleCollapsed: () => ({
    type: actions.COLLPSE_CHANGE,
  }),

  toggleAll: (width, height) => {
    const view = getView(width);
    const collapsed = view !== 'DesktopView';
    return {
      type: actions.TOGGLE_ALL,
      collapsed,
      view,
      height,
    };
  },

  toggleOpenDrawer: () => ({
    type: actions.COLLPSE_OPEN_DRAWER,
  }),

  changeOpenKeys: openKeys => ({
    type: actions.CHANGE_OPEN_KEYS,
    openKeys,
  }),

  changeOpenLayers: openLayers => ({
    type: actions.CHANGE_OPEN_LAYERS,
    openLayers,
  }),

  changeNetwork: network => ({
    type:actions.CHANGE_NETWORK,
    network,
  }),

  changeCurrent: current => ({
    type: actions.CHANGE_CURRENT,
    current,
  }),

  changeIsDashboardVisible: isDashboardVisible => {window.isDashboardVisible = true; return {
    type: actions.CHANGE_IS_DASHBOARD_VISIBLE,
    isDashboardVisible,
  }},

  changeShowSubscriptionPopupOverlay: showSubscriptionPopupOverlay => ({
    type: actions.CHANGE_SHOW_SUBSCRIPTION_POPUP_OVERLAY,
    showSubscriptionPopupOverlay
  }),

  clearMenu: () => ({
    type: actions.CLEAR_MENU
  }),
};
export default actions;
